import { useCallback, useContext, useEffect, useState } from "react";
import { sample } from "lodash";
import { Stack, Box, useTheme, Snackbar } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import { play, playInspiredByTrack } from "../spotify";
import AlbumCard from "../components/AlbumCard";
import { useSelectionHistory } from "../util/selectionHistory";
import {
  SkipNextTwoTone,
  SkipPreviousTwoTone,
  AutoAwesome,
} from "@mui/icons-material";
import { SpotifyPlaybackContext } from "../spotify/SpotifyPlaybackProvider";

interface Props {
  data: any[] | null;
}

const PLAYLIST_SIZE = 20;

export const AlbumView = ({ data }: Props) => {
  const theme = useTheme();
  const {
    data: history,
    loading: historyLoading,
    saveSelection,
  } = useSelectionHistory();

  const [album, setAlbum] = useState<any>();
  const [historyIndex, setHistoryIndex] = useState<number | null>(null);
  const { reload: reloadPlaybackArea } = useContext(SpotifyPlaybackContext);
  const [device, setDevice] = useState<string | null>();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const pickAlbum = useCallback(
    (album?: any) => {
      if (album) {
        setAlbum(album);
      } else {
        const a = sample(data);
        console.log("pick", a);
        setAlbum(a.album);
        saveSelection(a.album);
        setHistoryIndex(0);
      }
    },
    [data, saveSelection]
  );

  const pickHistory = useCallback(
    (index: number) => {
      const album = history && history[index];
      if (album) {
        setHistoryIndex(index);
        setAlbum(null);
      }
    },
    [history]
  );

  const nextRandom = useCallback(() => {
    setHistoryIndex(null);
    setAlbum(null);
  }, []);

  const playAlbum = useCallback(async () => {
    if (device && album?.uri) {
      console.log("play", device, album.uri);
      await play(device, album.uri);
      setTimeout(reloadPlaybackArea, 500);
    }
  }, [album, device, reloadPlaybackArea]);

  const playInspired = useCallback(async () => {
    if (device && album?.uri) {
      console.log("random", device, album);
      const randomTrack = sample(album.tracks.items);
      await playInspiredByTrack(device, randomTrack, PLAYLIST_SIZE);
      setTimeout(reloadPlaybackArea, 500);
      setOpen(true);
    }
  }, [album, device, reloadPlaybackArea]);

  useEffect(() => {
    if (!historyLoading && data && !album) {
      setTimeout(
        () =>
          history?.length && historyIndex !== null
            ? pickAlbum(history[historyIndex])
            : pickAlbum(),
        300
      );
    }
  }, [
    album,
    data,
    history,
    historyIndex,
    historyLoading,
    pickAlbum,
    pickHistory,
  ]);

  return (
    <Stack
      sx={{ marginX: "auto", marginY: "auto", alignItems: "center" }}
      spacing={1}
      direction="row"
    >
      <div
        style={{
          opacity: 0.5,
          cursor: "pointer",
          width: "3em",
          textAlign: "center",
        }}
      >
        <motion.div
          hidden={!!history && historyIndex === history.length - 1}
          style={{
            backgroundColor: theme.palette.primary.main + "00",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
          whileHover={{
            backgroundColor: theme.palette.primary.main + "20",
          }}
          onClick={() => {
            pickHistory(historyIndex !== null ? historyIndex + 1 : 0);
          }}
        >
          <SkipPreviousTwoTone color="primary" />
        </motion.div>
      </div>
      <Box sx={{ width: 350 }}>
        <AnimatePresence>
          {album && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
            >
              <AlbumCard
                album={album}
                onDeviceChange={setDevice}
                canPlay={album?.uri && device}
                onPlay={playAlbum}
                onIdea={playInspired}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <div
        style={{
          opacity: 0.5,
          cursor: "pointer",
          width: "3em",
          textAlign: "center",
        }}
      >
        <motion.div
          style={{
            backgroundColor: theme.palette.primary.main + "00",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
          whileHover={{
            backgroundColor: theme.palette.primary.main + "20",
          }}
          onClick={() => {
            historyIndex === 0
              ? nextRandom()
              : pickHistory(historyIndex !== null ? historyIndex - 1 : 0);
          }}
        >
          {historyIndex === 0 || historyIndex === null ? (
            <AutoAwesome color="primary" />
          ) : (
            <SkipNextTwoTone color="primary" />
          )}
        </motion.div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`Queued ${PLAYLIST_SIZE} random tracks inspired by this album`}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      />
    </Stack>
  );
};
