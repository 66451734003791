import { PlayCircleFilledOutlined, TipsAndUpdates } from "@mui/icons-material";
import {
  CardActionArea,
  CardActions,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import DeviceSelector from "./DeviceSelector";
import { ReactComponent as SpotifyIcon } from "../spotify.svg";

interface Props {
  album: SpotifyApi.AlbumObjectSimplified;
  canPlay: boolean;
  onPlay: () => void;
  onIdea: () => void;
  onDeviceChange: (deviceId: string | null) => void;
}
export default function AlbumCard({
  album,
  onPlay,
  onIdea,
  canPlay,
  onDeviceChange,
}: Props) {
  const maxWidth = Math.min(window.innerWidth - 50, 345);

  return (
    <>
      <Card sx={{ maxWidth, borderRadius: 0 }}>
        <CardActionArea disabled={!canPlay} onClick={onPlay}>
          <CardMedia
            sx={{
              height: maxWidth,
            }}
            component="img"
            image={album.images[0].url}
            alt="album artwork"
          />

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {album.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {album.artists.map((artist) => artist.name).join(" | ")}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ paddingLeft: 2, paddingBottom: 2, paddingRight: 2 }}>
          <DeviceSelector
            onChange={(device: string | null) => {
              onDeviceChange(device);
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <PlayCircleFilledOutlined
            sx={{ cursor: "pointer" }}
            color={canPlay ? "primary" : "disabled"}
            fontSize={"large"}
            onClick={onPlay}
          />
          <TipsAndUpdates
            sx={{ cursor: "pointer" }}
            color={canPlay ? "primary" : "disabled"}
            fontSize={"large"}
            onClick={onIdea}
          />
        </CardActions>
      </Card>
      <div
        style={{
          marginTop: "1em",
          width: "50%",
          margin: "auto",
          padding: ".5em",
        }}
      >
        <a
          target="_blank"
          href={album.uri}
          rel="noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            color: "black",
            textDecoration: "none",
          }}
        >
          <SpotifyIcon style={{ height: 25, marginRight: ".5em" }} />
          Open Spotify
        </a>
      </div>
    </>
  );
}
