import * as localforage from "localforage";

const prefix = "spotifyApiCache_";

export const readCache = async (key: string) => {
  const value = (await localforage.getItem(prefix + key)) as any;
  return value ? JSON.parse(value) : null;
};

export const writeCache = (key: string, value: any) =>
  localforage.setItem(prefix + key, JSON.stringify(value));

export const clearCache = (key: string) => localforage.removeItem(prefix + key);
