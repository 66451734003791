import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  Grow,
  Paper,
  Popper,
  Slider,
} from "@mui/material";
import { VolumeDown, VolumeMute, VolumeUp } from "@mui/icons-material";
import { SpotifyPlaybackContext } from "../spotify/SpotifyPlaybackProvider";
import { throttle } from "lodash";

interface Props {
  onChange: (val: any) => void;
}

export default function Volume({ onChange }: Props) {
  const [open, setOpen] = useState(false);
  const [defaultVolume, setDefaultVolume] = useState(0);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { data } = useContext(SpotifyPlaybackContext);

  useEffect(() => {
    data?.device?.volume_percent !== undefined &&
      data?.device?.volume_percent !== null &&
      setDefaultVolume(data?.device?.volume_percent);
  }, [data]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledChangeHandler = useCallback(
    throttle(onChange, 500, {
      leading: false,
      trailing: true,
    }),
    [onChange]
  );

  const updateVolume = useCallback(
    (val: number) => {
      throttledChangeHandler(val);
      setDefaultVolume(val);
    },
    [throttledChangeHandler]
  );

  return (
    <div style={{ position: "relative" }}>
      <IconButton ref={anchorRef} onClick={handleToggle}>
        {defaultVolume === 0 ? (
          <VolumeMute sx={{ color: "#FFF" }} />
        ) : defaultVolume > 50 ? (
          <VolumeUp sx={{ color: "#FFF" }} />
        ) : (
          <VolumeDown sx={{ color: "#FFF" }} />
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "left-start" ? "right top" : "right bottom",
            }}
          >
            <Paper sx={{ height: 300, paddingY: "1em" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Slider
                  orientation="vertical"
                  valueLabelDisplay="auto"
                  defaultValue={defaultVolume}
                  onChange={(event, val) => {
                    updateVolume(val as number);
                  }}
                  onChangeCommitted={(event, val) => {
                    updateVolume(val as number);
                    setOpen(false);
                  }}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
