import { ReactNode, useCallback, useContext, useEffect } from "react";
import {
  AuthContext,
  AuthProvider,
  IAuthContext,
  TAuthConfig,
  TRefreshTokenExpiredEvent,
} from "react-oauth2-code-pkce";
import Cookies from "js-cookie";
import { Box } from "@mui/material";

import { setToken, Scopes } from "./spotify";
import MainView from "./containers/MainView";
import { ColorModeProvider } from "./context/ColorModeProvider";
import { SpotifyPlaybackProvider } from "./spotify/SpotifyPlaybackProvider";
import "./App.css";
import { LoginCard } from "./components/LoginCard";

const SPOTIFY_CLIENT_ID = "793f6cef52054308a45e00efdd9ad19f";

const authConfig: TAuthConfig = {
  autoLogin: true,

  clientId: SPOTIFY_CLIENT_ID,
  authorizationEndpoint: "https://accounts.spotify.com/authorize",
  tokenEndpoint: "https://accounts.spotify.com/api/token",
  redirectUri: window.location.toString().replace(window.location.search, ""),
  scope: [
    Scopes.userFollowRead,
    Scopes.userLibraryRead,
    Scopes.userReadPlaybackState,
    Scopes.userModifyPlaybackState,
  ].join(" "),
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    window.confirm(
      "Session expired. Refresh page to continue using the site?"
    ) && event.login(),
};

interface Props {
  children: (props: {
    isLoggedIn: boolean;
    login: () => void;
    logout: () => void;
  }) => ReactNode;
}

const AuthTokenHandler = ({ children }: Props) => {
  const { token, login, logOut } = useContext<IAuthContext>(AuthContext);

  useEffect(() => {
    Cookies.set("spotifyAuthToken", token);
    setToken(token);
  }, [token]);

  const logout = useCallback(() => {
    Cookies.remove("spotifyAuthToken");
    logOut();
  }, [logOut]);

  return <>{children({ isLoggedIn: !!token, login, logout })}</>;
};

function App() {
  return (
    <ColorModeProvider>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <AuthProvider authConfig={authConfig}>
          <AuthTokenHandler>
            {({ isLoggedIn, login, logout }) => {
              return !isLoggedIn ? (
                <LoginCard login={login} />
              ) : (
                <SpotifyPlaybackProvider>
                  <MainView onLogout={logout} />
                </SpotifyPlaybackProvider>
              );
            }}
          </AuthTokenHandler>
        </AuthProvider>
      </Box>
    </ColorModeProvider>
  );
}

export default App;
