import React, { createContext, useMemo } from "react";
import { usePlaybackState } from ".";

interface PlaybackContextValues {
  data: SpotifyApi.CurrentPlaybackResponse | null;
  loading: boolean;
  reload: () => void;
}

const defaultPlaybackContexValues: PlaybackContextValues = {
  data: null,
  loading: false,
  reload: () => {},
};

export const SpotifyPlaybackContext = createContext<PlaybackContextValues>(
  defaultPlaybackContexValues
);

export const SpotifyPlaybackProvider: React.FC<any> = ({ children }) => {
  const { data, loading, reload } = usePlaybackState();

  const providerValue = useMemo(
    () => ({
      data,
      loading,
      reload,
    }),
    [data, loading, reload]
  );

  return (
    <SpotifyPlaybackContext.Provider value={providerValue}>
      {children}
    </SpotifyPlaybackContext.Provider>
  );
};
