import { Card } from "@mui/material";
import { ReactComponent as SpotifyIcon } from "../spotify.svg";

interface Props {
  login: () => void;
}

export const LoginCard = ({ login }: Props) => {
  return (
    <Card
      sx={{
        marginX: "auto",
        marginY: "auto",
        borderRadius: 5,
        padding: 5,
        cursor: "pointer",
      }}
      onClick={() => login()}
    >
      <SpotifyIcon />
      <span>Continue with Spotify</span>
    </Card>
  );
};
