import { useCallback, useEffect, useState } from "react";
import { readCache, writeCache } from "./cache";

export const useSelectionHistory = () => {
  const cacheKey = "useSelectionHistory";
  const [data, setData] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const saveSelection = useCallback(
    async (selection: any) => {
      const result = data ? [selection, ...data].slice(0, 100) : [selection];
      setData(result);
      writeCache(cacheKey, result);
    },
    [data]
  );

  useEffect(() => {
    if (!data) {
      setLoading(true);
      readCache(cacheKey).then((cachedData: any) => {
        console.log("history loaded", cachedData);
        if (cachedData) {
          setData(cachedData);
        } else {
          setData([]);
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, data, saveSelection };
};
