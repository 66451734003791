import { useContext, useState } from "react";
import { playPauseActiveDevice, skipTrackActiveDevice } from "../spotify";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { Fab, Tooltip } from "@mui/material";
import { Pause, PlayArrow, SkipNext } from "@mui/icons-material";
import { SpotifyPlaybackContext } from "../spotify/SpotifyPlaybackProvider";
import { TrackCount } from "../components/TrackCount";
import Zoom from "@mui/material/Zoom";
import { AlbumCardTooltip } from "../components/AlbumCardTooltip";

const StyledFab = styled(Fab)({
  marginRight: 10,
});

export default function PlayPause() {
  const [disabled, setDisabled] = useState(true);
  const [playing, setPlaying] = useState(false);
  const { data, loading, reload } = useContext(SpotifyPlaybackContext);

  const isAlbumMode = data?.context?.type === "album";

  const handleClick = () => {
    playPauseActiveDevice(playing);
    setPlaying(!playing);
  };

  const handleNext = () => {
    skipTrackActiveDevice();
  };

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (data) {
      setPlaying(data.is_playing);
      setDisabled(false);
    }
  }, [data]);

  return (
    <div
      style={{
        position: "relative",
        width: "calc(100% - 120px)",
        maxWidth: "60%",
        textAlign: "center",
      }}
    >
      <>
        {data ? (
          <>
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                top: -50,
                left: 0,
                right: 0,
                margin: "0 auto",
              }}
            >
              <AlbumCardTooltip
                TransitionComponent={Zoom}
                track={data?.item as SpotifyApi.TrackObjectFull}
                album={(data?.item as SpotifyApi.TrackObjectFull)?.album}
                deviceName={data?.device?.name}
              >
                <StyledFab style={{ cursor: "default" }} onClick={reload}>
                  <img
                    width="64"
                    alt="album art"
                    src={
                      (data?.item as SpotifyApi.TrackObjectFull)?.album
                        .images[0].url
                    }
                  />
                </StyledFab>
              </AlbumCardTooltip>
              <StyledFab
                onClick={handleClick}
                disabled={disabled}
                color="secondary"
              >
                {playing ? <Pause /> : <PlayArrow />}
              </StyledFab>
              <StyledFab
                onClick={handleNext}
                disabled={disabled}
                color="secondary"
              >
                <SkipNext />
              </StyledFab>
            </div>
            <div style={{ marginTop: "1em" }}>
              <TrackCount
                progressPercent={
                  data.item &&
                  data.progress_ms &&
                  (data.progress_ms / data.item?.duration_ms) * 100
                }
                number={
                  isAlbumMode
                    ? (data?.item as SpotifyApi.TrackObjectFull)?.track_number
                    : 1
                }
                total={
                  isAlbumMode
                    ? (data?.item as SpotifyApi.TrackObjectFull)?.album
                        .total_tracks
                    : 1
                }
              />
            </div>
          </>
        ) : null}

        {!data?.device && !loading ? (
          <Tooltip title="Click to refresh playback state">
            <span onClick={reload}>No Active Device</span>
          </Tooltip>
        ) : null}
      </>
    </div>
  );
}
