import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { AutoMode } from "@mui/icons-material";
import { playTracks, useFollowedAlbums } from "../spotify";
import { useCallback, useState, useContext } from "react";
import { sample, sampleSize } from "lodash";
import Snackbar from "@mui/material/Snackbar";
import { SpotifyPlaybackContext } from "../spotify/SpotifyPlaybackProvider";

const PLAYLIST_SIZE = 20;
export function Randomizer() {
  const { data } = useFollowedAlbums();
  const { data: contextData } = useContext(SpotifyPlaybackContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const createRandomPlaylist = useCallback(() => {
    const tracks = sampleSize(data, PLAYLIST_SIZE).map((entry) =>
      sample(entry.album.tracks.items)
    );

    if (contextData?.device?.id) {
      playTracks(contextData?.device.id, tracks);
      setOpen(true);
      setMessage(`Queued ${PLAYLIST_SIZE} random tracks from your library`);
    } else {
      setOpen(true);
      setMessage(`No Active Device`);
    }
  }, [contextData?.device.id, data]);

  return (
    <Box>
      <IconButton onClick={createRandomPlaylist} color="inherit">
        <AutoMode />
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      />
    </Box>
  );
}
