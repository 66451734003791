import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useDevices } from "../spotify";
import { useEffect } from "react";
import {
  Computer,
  DevicesOther,
  Smartphone,
  Speaker,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";

interface DeviceIconProps {
  deviceType: string;
}
const DeviceIcon = ({ deviceType }: DeviceIconProps) => {
  return deviceType === "Computer" ? (
    <Computer />
  ) : deviceType === "Smartphone" ? (
    <Smartphone />
  ) : deviceType === "Speaker" ? (
    <Speaker />
  ) : (
    <DevicesOther />
  );
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  options: SpotifyApi.UserDevice[];
  onClose: (value: SpotifyApi.UserDevice | null) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, options, selectedValue, open } = props;
  const theme = useTheme();

  const handleClose = () => {
    onClose(options.find((option) => option.id === selectedValue) || null);
  };

  const handleListItemClick = (value: SpotifyApi.UserDevice) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose Playback Device</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((option) => (
          <ListItem
            button
            onClick={() => handleListItemClick(option)}
            key={option.id}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.secondary.contrastText
                      : "white",
                }}
              >
                <DeviceIcon deviceType={option.type} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

interface Props {
  onChange: (deviceId: string | null) => void;
}

export default function DeviceSelector({ onChange }: Props) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>();
  const { data, loading } = useDevices();

  const activeDevice = useMemo(() => {
    return data?.devices.find((device) => device.is_active) || null;
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: SpotifyApi.UserDevice | null) => {
    setOpen(false);
    setSelectedValue(value?.name || null);
    onChange(value?.id || null);
  };

  useEffect(() => {
    if (activeDevice && !selectedValue) {
      setSelectedValue(activeDevice.name);
      onChange(activeDevice.id);
    }
  }, [onChange, activeDevice, selectedValue]);

  return !loading && data ? (
    <>
      {!selectedValue ? (
        <Button variant="contained" onClick={handleClickOpen}>
          Select Device
        </Button>
      ) : (
        <Button
          sx={{ marginLeft: 1 }}
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
        >
          {selectedValue}
        </Button>
      )}
      <SimpleDialog
        selectedValue={activeDevice?.id || ""}
        options={data.devices}
        open={open}
        onClose={handleClose}
      />
    </>
  ) : null;
}
