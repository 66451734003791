import Cookies from "js-cookie";
import wretch from "wretch";
import QueryAddon from "wretch/addons/queryString";
import { useCallback, useEffect, useState } from "react";
import { clearCache, readCache, writeCache } from "../util/cache";
import { useInterval } from "../util/useInterval";

let token_ = Cookies.get("spotifyAuthToken");
console.log("startup token", token_);

let api = wretch("https://api.spotify.com/v1/", { mode: "cors" })
  .addon(QueryAddon)
  .options({
    headers: { Authorization: `Bearer ${token_}` },
  })
  .errorType("json")
  .resolve(async (r) => {
    const response = await r.res();
    if (response.status === 204) return r.text();
    return r.json();
  });

export const setToken = (token: string) => {
  token_ = token;
  api = api.options({ headers: { Authorization: `Bearer ${token}` } });
};

export const Scopes = {
  ugcImageUpload: "ugc-image-upload",
  userFollowRead: "user-follow-read",
  userFollowModify: "user-follow-modify",
  userReadRecentlyPlayed: "user-read-recently-played",
  userTopRead: "user-top-read",
  userReadPlaybackPosition: "user-read-playback-position",
  userLibraryRead: "user-library-read",
  userLibraryModify: "user-library-modify",
  userReadPlaybackState: "user-read-playback-state",
  userReadCurrentlyPlaying: "user-read-currently-playing",
  userModifyPlaybackState: "user-modify-playback-state",
  playlistReadCollaborative: "playlist-read-collaborative",
  playlistModifyPrivate: "playlist-modify-private",
  playlistModifyPublic: "playlist-modify-public",
  playlistReadPrivate: "playlist-read-private",
  streaming: "streaming",
  appRemoteControl: "app-remote-control",
  userReadEmail: "user-read-email",
  userReadPrivate: "user-read-private",
};

const getAllPages = async <T>(
  route: string,
  callback: React.Dispatch<React.SetStateAction<loadingMetaData>>
) => {
  let options = {
    limit: 50,
    offset: 0,
  };

  const data = [] as Array<T>;
  let result;

  let completed = 0;
  do {
    result = (await api
      .query(options)
      .get(route)) as SpotifyApi.PagingObject<T>;

    const progress = {
      completed: ++completed,
      total: Math.ceil(result.total / result.limit),
    };
    callback(progress);

    data.push(...result.items);
    console.log(data.length);

    options.offset += options.limit;
  } while (result.next !== null);

  return data;
};

interface loadingMetaData {
  completed: number;
  total: number;
}

export const useFollowedAlbums = () => {
  const cacheKey = "useFollowedAlbums";
  const [data, setData] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<loadingMetaData>({
    completed: 0,
    total: 1,
  });

  const reload = useCallback(async () => {
    await clearCache(cacheKey);
    setData(null);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!data && !loading) {
      setLoading(true);
      readCache(cacheKey).then((cachedData: any) => {
        if (cachedData) {
          console.log("library cache", cachedData);
          setData(cachedData);
          setLoading(false);
          return;
        }
        getAllPages("me/albums", setMetaData).then((result: any) => {
          setData(result);
          writeCache(cacheKey, result);
          setLoading(false);
        });
      });
    }
  }, [data, loading]);

  return { loading, metaData, data, reload };
};

export const useDevices = () => {
  const [data, setData] = useState<SpotifyApi.UserDevicesResponse | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data && !loading) {
      setLoading(true);
      (
        api.get("me/player/devices") as Promise<SpotifyApi.UserDevicesResponse>
      ).then((result: any) => {
        setData(result);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { loading, data };
};

const NODATA = Symbol("NO _DATA");
export const usePlaybackState = () => {
  const [data, setData] = useState<
    SpotifyApi.CurrentPlaybackResponse | typeof NODATA | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [reloadInterval, setReloadInterval] = useState<number | null>(null);

  const reload = useCallback(() => {
    setShouldReload(true);
  }, []);

  // There is no socket API for playback status available.
  // This really appears to be the expected way to do this.
  //https://community.spotify.com/t5/Closed-Ideas/Developer-Access-to-websockets-for-live-playback-information/idi-p/5019061
  //https://github.com/spotify/web-api/issues/1558
  useInterval(reload, reloadInterval);

  useEffect(() => {
    if (shouldReload || (!data && !loading)) {
      setLoading(true);
      setShouldReload(false);
      (api.get("me/player") as Promise<SpotifyApi.CurrentPlaybackResponse | "">) //"" returned on 204/no content response
        .then((result) => {
          if (result === "") {
            setData(NODATA);
          } else {
            result.is_playing
              ? setReloadInterval(5 * 1000)
              : setReloadInterval(null);
            setData(result);
          }
        })
        .catch((e) => {
          console.log(e);
          setData(NODATA);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [data, loading, shouldReload]);

  return {
    data: data === NODATA ? null : data,
    loading,
    reload,
  };
};

export const playPauseActiveDevice = (playing: boolean) =>
  playing ? api.put({}, "me/player/pause") : api.put({}, "me/player/play");

export const skipTrackActiveDevice = () => api.post({}, "me/player/next");

export const play = (deviceId: string, context: string) =>
  api
    .query({
      device_id: deviceId,
    })
    .put(
      {
        context_uri: context,
      },
      "me/player/play"
    );

export const playInspiredByTrack = async (
  deviceId: string,
  track: any,
  playlistSize: number
) => {
  // populate queue with recommendations seeded by track
  const recommendations: any = await api
    .query({
      limit: playlistSize - 1,
      seed_tracks: track.id,
    })
    .get("recommendations");
  await api
    .query({
      device_id: deviceId,
    })
    .put(
      {
        uris: [
          track.uri,
          ...recommendations.tracks.map((recTrack: any) => recTrack.uri),
        ],
      },
      "me/player/play"
    );
};

export const playTracks = async (deviceId: string, tracks: any[]) => {
  await api
    .query({
      device_id: deviceId,
    })
    .put(
      {
        uris: tracks.map((recTrack: any) => recTrack.uri),
      },
      "me/player/play"
    );
};

export const setVolume = (volume_percent: number) =>
  api
    .query({
      volume_percent,
    })
    .put({}, "me/player/volume");
