import * as React from "react";
import {
  ClickAwayListener,
  IconButton,
  Button,
  MenuList,
  Grow,
  Paper,
  Popper,
  MenuItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

interface Props {
  onLogout: () => void;
  onReloadData?: () => void;
}

export default function ThreeDotMenu({ onLogout, onReloadData }: Props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleReload = () => {
    setOpen(false);
    onReloadData && onReloadData();
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton ref={anchorRef} onClick={handleToggle}>
        <MoreVert />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="left-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "left-start" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem>
                    <Button onClick={onLogout}>Logout</Button>
                  </MenuItem>
                  {onReloadData ? (
                    <MenuItem>
                      <Button onClick={handleReload}>Reload Data</Button>
                    </MenuItem>
                  ) : null}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
