import { ReactNode, useState, useMemo, createContext, useContext } from "react";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";

const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

interface Props {
  children?: ReactNode;
}

export const useColorModeContext = () => useContext(ColorModeContext);

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#5340b9",
    },
    secondary: {
      main: "#2fb8d8",
    },
    divider: "rgba(255,255,255,0.29)",
  },
  typography: {
    fontFamily: "Source Sans Pro",
  },
};

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#978cc7",
    },
    secondary: {
      main: "#56b8d0",
    },
    divider: "rgba(255,255,255,0.29)",
  },
  typography: {
    fontFamily: "Source Sans Pro",
  },
};

const COLOR_MODE_COOKIE = "color_mode";

export function ColorModeProvider({ children }: Props) {
  const [mode, setMode] = useState<"light" | "dark">(() => {
    const cookieMode = Cookies.get(COLOR_MODE_COOKIE);
    if ((cookieMode && cookieMode === "light") || cookieMode === "dark") {
      return cookieMode;
    }
    return "light";
  });
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const nextMode = prevMode === "light" ? "dark" : "light";
          Cookies.set(COLOR_MODE_COOKIE, nextMode);
          return nextMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: "100%",
            background: theme.palette.mode === "dark" ? "#333" : "#F6F6FF",
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
