import styled from "@emotion/styled";
import _ from "lodash";
import { useTheme } from "@mui/material";
import { Property } from "csstype";

interface Props {
  number: number | null;
  total: number | null;
  progressPercent: number | null;
}

interface TrackBoxProps {
  flexBasis: number;
  backgroundColor: Property.BackgroundColor;
  backgroundPercent: number;
  foregroundColor: Property.BackgroundColor;
}

const TrackBox = styled.span((props: TrackBoxProps) => ({
  position: "relative",
  height: "5px",
  backgroundColor: props.backgroundColor,
  width: props.flexBasis + "%",
  margin: "2px",
  "&::after": {
    content: "''",
    background: props.foregroundColor,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: props.backgroundPercent + "%",
  },
}));

export const TrackCount = function ({ number, progressPercent, total }: Props) {
  const theme = useTheme();

  return number !== null && total !== null ? (
    <span style={{ display: "flex", flexDirection: "row" }}>
      {_.range(total).map((i) => (
        <TrackBox
          key={i}
          flexBasis={(total / number) * 100.0}
          backgroundPercent={
            progressPercent !== null && i + 1 === number
              ? 100 - progressPercent
              : 100
          }
          foregroundColor={theme.palette.secondary.light}
          backgroundColor={
            i + 1 < number
              ? theme.palette.secondary.light
              : theme.palette.primary.light
          }
        />
      ))}
    </span>
  ) : null;
};
