import { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import { ReactComponent as SpotifyIcon } from "../spotify.svg";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: " transparent",
    fontSize: 11,
  },
}));

interface Props extends Omit<TooltipProps, "title"> {
  track: SpotifyApi.TrackObjectFull | null;
  album: SpotifyApi.AlbumObjectSimplified | null;
  deviceName: string | null;
  children: ReactElement<any, any>;
}

const getTrackInfo = (track: SpotifyApi.TrackObjectFull | null) =>
  track ? track : { name: "No Track Data", track_number: 0 };

const getAlbumInfo = (album: SpotifyApi.AlbumObjectSimplified | null) =>
  album ? album : { name: "No Album Data", total_tracks: 0, artists: [] };

export const AlbumCardTooltip = ({
  album,
  track,
  deviceName,
  children,
  ...rest
}: Props) => (
  <LightTooltip
    enterTouchDelay={100}
    title={
      <Card>
        <CardContent sx={{ padding: "1em 1.5em" }}>
          <Typography variant="h5" component="div">
            {getTrackInfo(track).name}
          </Typography>
          <Typography gutterBottom component="div">
            Track {getTrackInfo(track).track_number} of{" "}
            {getAlbumInfo(album).total_tracks}
          </Typography>
          <Divider variant="fullWidth"></Divider>
          <Typography component="div">{getAlbumInfo(album).name}</Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            {getAlbumInfo(album)
              .artists.map((artist) => artist.name)
              .join(" | ")}
          </Typography>
          <Divider variant="fullWidth"></Divider>
          <Typography
            padding={0}
            marginTop={1}
            variant="body2"
            color="text.secondary"
          >
            {deviceName ? `on ${deviceName}` : ""}
          </Typography>
          {track ? (
            <div
              style={{
                margin: "auto",
                padding: ".5em",
              }}
            >
              <a
                target="_blank"
                href={track.uri}
                rel="noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <SpotifyIcon style={{ height: "25px", marginRight: ".5em" }} />
                Open Spotify
              </a>
            </div>
          ) : null}
        </CardContent>
        <div></div>
      </Card>
    }
    {...rest}
  >
    {children}
  </LightTooltip>
);
