import {
  Box,
  AppBar,
  Toolbar,
  LinearProgress,
  Typography,
} from "@mui/material";

import { AlbumView } from "./AlbumView";
import ThreeDotMenu from "../components/ThreeDotMenu";
import { setVolume, useFollowedAlbums } from "../spotify";
import PlayPause from "./PlayPause";
import Volume from "../components/Volume";
import { DarkModeSwitch } from "../components/DarkModeSwitch";
import { Randomizer } from "../components/Randomizer";

interface Props {
  onLogout: () => void;
}

export default function MainView({ onLogout }: Props) {
  const { data, loading, metaData, reload } = useFollowedAlbums();

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{ top: 0, bottom: "auto", flexDirection: "row" }}
      >
        <ThreeDotMenu onLogout={onLogout} onReloadData={reload} />
        <Box sx={{ flexGrow: 1 }} />
        <Randomizer />
      </AppBar>

      <Box sx={{ maxWidth: "100%", margin: "auto" }}>
        {loading ? (
          <Box sx={{ width: 350, maxWidth: "90%", margin: "auto" }}>
            <LinearProgress
              variant="determinate"
              value={(metaData.completed / metaData.total) * 100}
            />
            <Typography> Loading Album Data...</Typography>
          </Box>
        ) : (
          <AlbumView data={data} />
        )}
      </Box>

      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <DarkModeSwitch />
          <Box sx={{ flexGrow: 1 }} />
          <PlayPause />
          <Box sx={{ flexGrow: 1 }} />
          <Volume onChange={(val) => setVolume(val)} />
        </Toolbar>
      </AppBar>
    </>
  );
}
